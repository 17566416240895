<template>
    <div class="ai-main-chat">
       <div class="ai-main-chat-title">
        {{ categoryName }}
       </div>
       <div class="ai-main-chat-content" ref="chatContent">
        <div>
           
            <div class="ai-main-chat-content-item">
                <!-- 头像 -->
                <img class="photo-img" :src="robotInfo.photo" alt="">
                <div class="content" >

                    <span v-html="typedTips"></span>
                    <div class="copy cursor" @click="copyText">
                        复制
                    </div>
                </div>

            </div>
            <div v-for="(item, index) in chatList" :key="index" :class="{'user-robot-right': item.role == 'user'}" class="ai-main-chat-content-item">
              
                <!-- 头像 -->
                
                 <div v-if="item.role == 'assistant'">
                    <img class="photo-img" :src="robotInfo.photo" alt="">
                 </div>
                 
                <div class="content">
                   
                    <span v-if="item.role == 'assistant'" v-html="getContent(item.content)">
                       
                    </span>

                    <span v-if="item.role == 'user'" v-html="item.content"></span>

                
                    <div class="copy cursor" v-if="item.role == 'assistant'" @click="copyText">
                        复制
                    </div>
                </div>

                <div v-if="item.role == 'user'">
                    <img class="photo-img" :src="userInfo.photo" alt="">
                 </div>
            </div>
            <!-- 显示生成中字样 -->
            <div v-if="isGenerating" class="ai-main-chat-content-item generating">
              <img class="photo-img" :src="robotInfo.photo" alt="">
              <div>
                  <span class="loading-dots">生成中...</span>
              </div>
          </div>
        </div>

       </div>

       <div class="ai-main-chat-input">
        <div class="ai-main-chat-input-content" contenteditable="true" @input="updateMessage" @keydown="handleKeydown" ref="editableDiv" placeholder="输入对话内容"></div>
        <div class="send cursor" @click="sendMessage" :class="{'disabled': isTyping || isGenerating}">
            发送
        </div>
    </div>
       
    </div>
</template>

<script>
import { setCache, getCache, removeCache } from "@/utils/cache";
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AiMainChat',
  data() {
    return {
      message: '',
      isUserScrolling: false, // 用于检测用户是否手动滚动
      typedTips: '', // 直接显示的提示文本
      typedContents: [], // 打字机效果的聊天内容数组
      contentIntervals: [], // 聊天内容的定时器数组
      isTyping: false, // 标志位，表示是否有打字机效果正在进行中
      isGenerating: false // 标志位，表示是否有消息正在生成中
    };
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    },
    categoryName: {
      type: String,
      required: true
    },
    chatList: {
      required: true
    },
    tips: {
      type: String,
      required: true
    },
    robotInfo: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    is_end: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    chatList: {
      handler(newList, oldList) {
        if (Array.isArray(newList) && newList.length > oldList.length) {
          this.startTypingEffectForNewMessage(newList[newList.length - 1], newList.length - 1);
        }
        this.scrollToBottom();
        // 检查是否是新的消息，如果是，则清除 isGenerating 标志位
        if (Array.isArray(newList) && newList[newList.length - 1].role === 'assistant') {
          this.isGenerating = false;
        }
      },
      deep: true
    },
    tips: {
      handler(newTips) {
        this.typedTips = newTips; // 直接更新 typedTips
      }
    }
  },
  mounted() {
    // 初始化时滚动到底部
    this.scrollToBottom();
    // 监听滚动事件
    this.$refs.chatContent.addEventListener('scroll', this.handleScroll);
    // 初始化 typedTips
    this.typedTips = this.tips;
    // 初始化 typedContents 数组
    if (Array.isArray(this.chatList)) {
      this.initializeTypedContents();
    } else {
      this.typedContents = [];
    }
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    this.$refs.chatContent.removeEventListener('scroll', this.handleScroll);
    // 清除定时器
    this.clearTypingEffect();
  },
  methods: {
     getContent(content) {
      // 防御性处理：处理null/undefined输入
      if (!content) return '';
      // 统一转换为数组处理
      const parts = Array.isArray(content) ? content : [content];
      let output = '';
      
      parts.forEach(item => {
          // 空值过滤：跳过无效元素
         if (item === null || item === undefined) return;
          // 处理不同类型的内容
          // 安全访问对象属性
          const text = typeof item === 'object' 
            ? (item?.content ?? JSON.stringify(item)) // 使用空值合并运算符
            : String(item || ''); // 确保转换为字符串
          
          // 有效内容判断
          if (text.trim().length > 0) {
            output += `${text} `;
          }
        });
        
        return output.trim();

    },
    copyText() {
      const text = document.querySelector('.content p').innerText;
      navigator.clipboard.writeText(text).then(() => {
        alert('复制成功');
      }).catch(err => {
        console.error('复制失败: ', err);
      });
    },
    updateMessage() {
      this.message = this.$refs.editableDiv.innerText;
    },
    handleKeydown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // 阻止默认的换行行为
        this.sendMessage();
      }
    },
    sendMessage() {
      // if (this.isTyping || this.isGenerating) {
      //   alert('请等待当前消息显示完毕后再发送新的消息');
      //   return;
      // }

      const message = this.message.trim();
      
      if (message === '') {
        alert('请输入内容');
        return;
      }

      let conversation_id = getCache('conversation_id') ?? '';
      if (conversation_id === '') {
        alert('没有会话');
        return;
      }
      // 处理发送消息的逻辑，例如将消息发送到服务器或更新本地状态
      this.$emit('messages', {
        "conversation_id": conversation_id,
        "category_id": this.categoryId,
        "content": message,
        "role": "user"
      });
      // 将消息添加到聊天记录中
      // this.addChatList(message);
      this.message = ''; // 清空输入框
      this.$refs.editableDiv.innerText = ''; // 清空可编辑的 div
      this.isGenerating = true; // 设置标志位，表示消息正在生成中
    },
    scrollToBottom() {
      // 检查用户是否手动滚动
      if (this.isUserScrolling) return;

      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        if (chatContent) {
          chatContent.scrollTop = chatContent.scrollHeight;
        }
      });
    },
    handleScroll(event) {
      const chatContent = event.target;
      // 检查用户是否滚动到底部
      if (chatContent.scrollTop + chatContent.clientHeight >= chatContent.scrollHeight - 10) {
        this.isUserScrolling = false;
      } else {
        this.isUserScrolling = true;
      }
    },
    startTypingEffectForNewMessage(newMessage, index) {
      this.isTyping = true; // 设置标志位，表示打字机效果正在进行中
      this.clearTypingEffectForNewMessage(index);
      this.$set(this.typedContents, index, '');

      let contentString = this.getContentString(newMessage.content);
      let contentIndex = 0;
      this.contentIntervals[index] = setInterval(() => {
        if (contentIndex < contentString.length) {
          this.$set(this.typedContents, index, this.typedContents[index] + contentString[contentIndex]);
          contentIndex++;
          this.scrollToBottom(); // 确保每次添加新内容时滚动到底部
        } else {
          clearInterval(this.contentIntervals[index]);
          this.isTyping = false; // 打字机效果结束，清除标志位
        }
      }, 50); // 调整打字速度
    },
    initializeTypedContents() {
      this.typedContents = this.chatList.map(item => this.getContentString(item.content));
    },
    getContentString(content) {
        if (typeof content === 'string') {
            return content;
        } else if (Array.isArray(content)) {
            return content.map(part => part.content).join(' ');
        } else if (typeof content === 'object') {
            return Object.values(content).join(' ');
        }
        return '';
    },
    clearTypingEffectForNewMessage(index) {
      if (this.contentIntervals[index]) {
        clearInterval(this.contentIntervals[index]);
        this.contentIntervals[index] = null;
      }
    },
    clearTypingEffect() {
      this.contentIntervals.forEach(interval => {
        if (interval) {
          clearInterval(interval);
        }
      });
      this.contentIntervals = [];
      this.isTyping = false; // 确保在清除定时器时，标志位也被清除
    }
  }
}
</script>

<style scoped>
.loading-dots {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #4400ff, 0 0 20px #4400ff, 0 0 25px #4400ff, 0 0 30px #4400ff, 0 0 35px #4400ff;
    animation: neon 1.5s infinite alternate;
}

@keyframes neon {
    from {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #4400ff, 0 0 20px #4400ff, 0 0 25px #4400ff, 0 0 30px #4400ff, 0 0 35px #4400ff;
    }
    to {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #4400ff, 0 0 40px #4400ff, 0 0 50px #4400ff, 0 0 60px #4400ff, 0 0 70px #4400ff;
    }
}

.user-robot-right {
    justify-content: flex-end; /* 将内容向右对齐 */
    .photo-img {
        margin-right: 0;
        margin-left: 10px; /* 调整头像的间距 */
    }
    .content {
        background-color:#ddedff !important; /* 可选：更改用户消息的背景颜色 */
        border-radius: 12px 0 12px 12px; /* 调整背景圆角 */
    }
}

.ai-main-chat {
    display: flex;
    flex-direction: column;
    width: 90%;
    .ai-main-chat-title {
        margin-bottom: 10px;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
        line-height: 48px;
        color: #333;
        font-size: 16px;
        font-weight: 500;
    }

    .ai-main-chat-content {
        overflow-y: auto; /* 添加滚动条 */
        padding: 10px;
        height: calc(90vh - 250px);
        .ai-main-chat-content-item {
            display: flex;
            flex-direction: row;
            padding: 10px;
            .photo-img {
                border-radius: 0.5rem;
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
            .content {
                display: flex;
                flex-direction: column;
                width: 60%;
                font-size: 16px;
                color: #252525;
                background: #f7f8f9;
                border-radius: 0 12px 12px 12px;
                span {
                    padding: 15px;
                }
                .copy {
                    text-align: end;
                    margin-right: 15px;
                    margin-bottom: 15px;
                    color: #9fa2a6;
                }
            }
        }
        .ai-main-chat-content-item.generating {
            .content {
                background-color: #f0f0f0; /* 可选：更改生成中的背景颜色 */
            }
        }
    }

    .ai-main-chat-input {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        border: 1px solid hsla(0, 0%, 87%, .8);
        border-radius: 16px;
        height: 104px;
        width: 852px;
        .ai-main-chat-input-content {
            width: 90%;
            padding: 10px;
            border-radius: 8px;
            resize: none;
            outline: none;
            font-size: 16px;
            overflow-y: auto;
        }
        .send {
            padding-top: 50px;
            cursor: pointer;
            color: #1f4cff;
            font-size: 16px;
            font-weight: 500;
        }
        .send.disabled {
            cursor: not-allowed;
            color: #999;
        }
    }
}
</style>