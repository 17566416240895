//对于axios进行二次封装
import axios from "axios";
import nprogress, { done } from "nprogress";
//在当前模块中引入store
// import store from '@/store';
import { setToken, getToken, removeToken } from "@/utils/token";

//引入路由相关文件
import router from "@/router";
//如果出现进度条没有显示：一定是你忘记了引入样式了
import "nprogress/nprogress.css";

//底下的代码也是创建axios实例
let requests = axios.create({
  //基础路径
  baseURL: process.env.VUE_APP_API_URL,
  //请求不能超过5s
  timeout: 5000, 
  headers: {
    //请求头信息
    // "Content-Type": "application/x-www-form-urlencoded",
    // "Content-Type": "multipart/form-data",
    "Content-Type": "application/json",
   "x-requested-with": "XMLHttpRequest",
  },
});

//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
requests.interceptors.request.use(
  (config) => {
    //需要携带token带给服务器
    let token = getToken()
    if(token){
      config.headers.Authorization = 'bearer' + token;
    }
   

    // nprogress.start();
    return config;
  },(err) => {
    console.log("服务器请求数据失败",err)
    return Promise.reject(err);
  }
);

//响应拦截器----当服务器手动请求之后，做出响应（相应成功）会执行的
requests.interceptors.response.use(
  (res) => {
    let data = res.data;
    //进度条结束
    nprogress.done();
    return data;
  },
  (err) => {
    try {

      if (err.response.status === 422 || err.response.status === 400){
        removeToken()
        // 跳转
        return router.push({name: 'login'});
  
      }
      // console.log("服务器响应数据失败",err)
      
      if (err.response) {
        console.log('请求错误');
  
      } else if(err.request) {
        // 请求已发出，但没有收到响应
        //  alert(`请求已发出，但没有收到响应`);
        
      } else {
        // 发送请求时发生了一些错误
         console.log('请求配置错误:', err);
        //  alert(`请求配置错误`);
        
      }
      
      // 显示错误提示
      if (err.response && err.response.data) {
        return err.response.data;
      }
  
      // 记录日志
  
      return Promise.reject(err);
      
    } catch (error) {
      return Promise.reject(error);
      
    }
   

  }
);
//最终需要对外暴露（不对外暴露外面模块没办法使用）
//这里的代码是暴露一个axios实例
export default requests;



