<template>
    <div class="app">
        <navs></navs>
       <!-- <button @click="goToLogin">登录</button> -->
       
        <div class="main">
            <div class="qrecode">
            <div class="title">
                <p>面试岛</p>
            </div>
            
           
            <div>
              
            <div>
                <div v-if="qrcodeStatus == 'fail'" @click="getQrcode()">
                <div class="fail-text">
                   二维码失败
                </div>
            </div>
            <div  v-if="qrcodeStatus == 'expiry'" @click="getQrcode()"> 
                
                <div class="fail-text">
                    二维码失效
                </div>
            </div>
                <img :src="qrcode.url" alt=""></img>
            </div>
            
            </div>
            
            <div class="qrcode-buttom">
                <p>使用微信登录</p>
            </div>
        </div>

        </div>
       
        <footers />
        
    </div>
</template>

<script>
import {mapState,mapMutations,mapActions,mapGetters} from 'vuex'
import navs from '../../components/nav.vue'
import footers from '../../components/footers.vue'
import WebSocketClient from '@/utils/websocket.js';

export default {
    components: { navs, footers },
    data() {
        return {
            qrcodeStatus:'success',
            intervalId: null, // 用于存储定时器ID
            num:0,
            componentKey: Date.now()
            
        }
    },
    created() {
        this.socket = new WebSocketClient();
        this.socket.connect();
        this.socket.on('weChatOpenId', (data) => {
            this.getweChatOpenId(data);
        });


    },
    computed:{
        ...mapState('users',['qrcode']),
        ...mapGetters('users',['gettersQrcode','isLogin']),
    },
    mounted() {
        this.num = 0
        try {
            let uuid = this.qrcode.uuid?? "";
            this.getQrcode(uuid);
        } catch (error) {
            console.log(error);
        }
       
    },

    beforeDestroy() {
        // 清理工作
        clearInterval(this.intervalId)
        console.log('组件即将被销毁')
    },

    methods: {
        ...mapActions('users',['authLogin','buildQrcode','getOpenid']),

        async getweChatOpenId(openiRes) {
            console.log('openiRes::',openiRes);
            if (openiRes.original.status === 'success') {
            //    return this.goToLogin(res.data.openId)
                clearInterval(this.intervalId);
                return this.goToLogin(openiRes)
            } else {
                this.num++;
                console.log("num::",this.num);
                if (this.num * 3000 >  this.expiresSeconds) {
                    console.log("expire_seconds11::",  this.expiresSeconds)
                    this.qrcodeStatus = "expiry"  // 失效
                    this.num = 0
                    clearInterval(this.intervalId);
                    
                    return ;

                }
               
                return ;
            }
                            

        },
       
        /**
         * 获取二维码
         */
        async getQrcode($uuid = ""){
            this.buildQrcode({
                uuid:$uuid
            }).then(res=>{
                if (res.status == 'error') {
                    // 失败重新获取
                    this.qrcodeStatus = "fail" 
                    return ;

                } else {
                    this.expiresSeconds =  res.data.ticket.expire_seconds * 1000-2000;

                    // 刷新二维码更改状态
                    this.qrcodeStatus = "success" ;
                    const intervalId =  setInterval(() => {
                        this.socket.send('getLoginQrcodeOpenid',{
                        "uuid":res.data.ticket.uuid,
                        // "expireSeconds":res.data.ticket.expire_seconds
                        // "expireSeconds":res.data.ticket.expire_seconds
                    });

                    },3000);

                    this.intervalId = intervalId;

                    
                      
                }

               
            });
            return true;
            

        },
       async goToLogin(info){
            console.log("goToLogin::",info)
            this.authLogin({
                'open_id': info.original.data.openId,
                'password': info.original.data.password,
                'user_origin': 1, 
            }).then(res => {
               
                if (res == 'success') {
                    // this.$alert("登录成功")
                
                    // 登录成功跳转页面
                    return this.$router.push({path: '/'})
                    // return window.location.href = '/'
                   
                }
                return this.$alert("登录失败")

                }).catch(res=>{
                    return this.$alert("登录失败")
                    
            })
        }
       
    }
}
</script>

<style lang="scss" scoped>
.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F4F6F9;
    
}
.qrecode {
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 70vh;
    background-color: #FFFFFF;
    border-radius: 1rem;
    border: 1px solid #FFFFFF;
    margin-top: 5rem;
   
    .title{
        font-size:40px;
        font-family: cursive;
        color: #d342f4;
        margin-bottom: 10px;
    }

    
    
}

.qrcode-buttom {
    font-size:20px;
    font-family: cursive;
    color: #d342f4;
    margin-top: 10px;
        
}

.fail-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size:20px;
    font-family: cursive;
    width: 217px;
    height: 200px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 999;
    cursor: pointer;
}
</style>