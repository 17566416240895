<template>
  <div class="app">
    <navs />
    <div class="main container">
      <div>
        <a-tabs default-active-key="1" @change="callback">
          <a-tab-pane key="1" tab="全部消息" force-render>
            <systemMessage />
          </a-tab-pane>

          <a-tab-pane key="2" tab="未读消息" force-render>
            <systemMessage />
          </a-tab-pane>

          <a-tab-pane key="3" tab="已读消息" force-render>
            <systemMessage />
          </a-tab-pane>
        
        </a-tabs>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import navs from '../../components/nav.vue';
import footers from '../../components/footers.vue';
import systemMessage from '../../components/systemMessage.vue';

export default {
  name: 'messages',
  components: {
    navs,
    footers,
    systemMessage
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    callback(key) {
      console.log(key);
    },
  },
};
</script>

<style scoped lang="scss">
.main{
  
  background-color: #fff;
}
.main-row {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.left-col {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  margin-right: 1rem;
  max-width: 90%;
}

.tab-item {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  cursor: pointer;
}
</style>