import { getToken } from "@/utils/token";

class WebSocketClient {
    constructor(url) {
        this.url = 'wss://admin.teaim.cn/ws';
        // this.url = 'ws://admin.mianshidao.gzy/ws';
        this.ws = null;
        this.listeners = {};
        this.heartbeatInterval = null; // 心跳间隔
        this.heartbeatIntervalTime = 25000; // 心跳间隔时间（60秒）
        this.lastHeartbeatTime = null; // 上次心跳时间
        this.heartbeatTimeout = 60000; // 心跳超时时间（60秒）
    }

    connect() {
        let token = getToken() ?? "";
        // if (token.length <= 0) {
        //   console.error('token 为空');
        //   return;
        // }
        this.ws = new WebSocket(this.url);

        this.ws.onopen = () => {
            // console.log('WebSocket connection established');
            // this.emit('open');
            // console.log('WebSocket 连接已打开');
            //启动心跳检测
            this.ws.send('2')

            // 发送初始消息（可选）
            this.ws.send('40');
        };
        


        this.ws.onmessage = (res) => {

            // this.emit('message', event.data);
            console.log("消息：：",res)
            let data = res.data;
            let substr = data;
            // 验证登录
            if (data.length > 1) {
              substr = data.substr(0, 1);
            }
    
            switch (substr) {
              case '1': // 关闭
                // console.log('onmessage收到消息1:', substr);
                this.disconnectWebSocket();
                break;
              case '2': // 发送心跳包
                // console.log('onmessage收到消息2:', substr);
                
                this.startHeartbeat();
                break;
              case '3':
                // console.log('pong:', substr); 
                // 重新安排心跳检测
                 this.schedulePing();   
                 break;
              case '4': // 获取消息
                // console.log('onmessage收到消息4:', substr);
                // 数据包解析
                this.packet(data);
                break;
              default:
                // this.disconnectWebSocket();
                break;
            }
        };

        this.ws.onclose = () => {
            // // console.log('WebSocket connection closed');
            // this.emit('close');
            // console.log('WebSocket 连接已关闭');
            // 重新连接 WebSocket
            setTimeout(() => {
              this.connect();
            }, this.heartbeatIntervalTime)
        };

        this.ws.onerror = (error) => {
          console.error('WebSocket 错误:', error);
          // 重新连接 WebSocket
          setTimeout(() => {
            this.connect();
          }, this.heartbeatIntervalTime);
        };
    }

    // 断开连接
    disconnectWebSocket() {
      if (this.ws) {
        this.close();
      }
      this.stopHeartbeat();
    }
     // 重新连接 WebSocket
     reconnectWebSocket() {
      // this.disconnectWebSocket();
      setTimeout(() => {
        this.connect();
      }, this.heartbeatIntervalTime); // 5秒后重新连接
    }

    stopHeartbeat() {
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
    }
    /**
     * 重新安排心跳检测
     */
    schedulePing() {
      this.stopHeartbeat();
      this.startHeartbeat();
    }

    startHeartbeat() {
     
      this.heartbeatInterval = setInterval(() => {
        if (this.ws) {
         
          // 发送心跳包
          this.ws.send('2');
          // console.log('ping:2');

          // 检查心跳超时
          if (this.lastHeartbeatTime && Date.now() - this.lastHeartbeatTime > this.heartbeatTimeout) {
            // console.log('心跳超时，断开重新连接');
            this.reconnectWebSocket();
          }
        }
       
      }, this.heartbeatIntervalTime);

     
    }

    packet(data) {
      let type = data.substr(1, 1);
      let packetInfo = data.substring(2);
      if (typeof packetInfo === 'string' && packetInfo.trim().length > 0) {
        packetInfo = JSON.parse(packetInfo);
      }
      switch (type) {
        case '0':
          // console.log('packetInfo0::', type);
          break;
        case '2':
          let event = packetInfo[0] ?? "";
          if (event.length <= 0) return;
          this.emit(event, packetInfo[1]);
          break;
        default:
          // console.log('packetInfo::', type);
          break;
      }
    }


    /**
     * 发送数据到WebSocket连接
     * 
     * 此方法检查WebSocket连接是否处于打开状态，如果是，则发送数据
     * 如果WebSocket连接未打开，则在控制台输出错误信息
     * 
     * @param {object} data - 要发送的数据
     */
    send(event,data) {
       
        // 检查WebSocket连接是否已创建且处于打开状态
        if (this.ws) {
            // 发送数据
            if (event.length > 0) {
              data = JSON.stringify([event,data]);
              // data = '42' + data;
            }
            // console.log('send:', data);
            this.ws.send(data);
        } else {
            // 输出错误信息到控制台
            console.error('WebSocket is not open');
        }
    }

    /**
     * 监听指定事件，并添加事件处理回调函数
     * 
     * 当指定的事件发生时，该方法确保回调函数能够被调用此方法可以多次调用，以添加多个回调函数到同一事件
     * 
     * @param {string} event - 事件名称，用于标识特定的事件
     * @param {function} callback - 事件发生时要执行的回调函数
     */
    on(event, callback) {
        // 检查当前事件是否有对应的回调函数数组，如果没有则创建一个空数组
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        // 将新的回调函数添加到对应事件的回调函数数组中
        this.listeners[event].push(callback);
    }

    /**
     * 触发指定事件，并传递数据给该事件的所有监听器
     * 
     * @param {string} event - 要触发的事件名称
     * @param {*} data - 传递给事件监听器的数据
     */
    emit(event, data) {
        // 检查是否有监听指定事件的回调函数
        if (this.listeners[event]) {
            // 遍历事件的所有回调函数，并调用它们，传递数据参数给它们
            this.listeners[event].forEach(callback => callback(data));
        }
    }

    close() {
        if (this.ws) {
            this.ws.close();
        }
    }
}

export default WebSocketClient;